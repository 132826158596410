@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animated-bg {
  transition: background-color 0.3s ease-in-out;
  /* Adjust duration and easing as needed */
}

.header-link:hover {
  color: rgb(242 158 19 / var(--tw-bg-opacity));
}

.orange-button:hover {
  background: linear-gradient(65deg, #F29E13 0, #f6b54a 100%);
}

.trial-button:hover {
  box-shadow: 1px 1px 50px rgba(0,0,0,0.25);
}